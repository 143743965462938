import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction002 from './Introduction002'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: 0;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 100px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;

      &.img-graph {
        padding-bottom: 10px;
      }
    }
  }

  .toc {
    border: 1px solid #ababab;
    padding: 20px 40px;
    margin: 0 auto 30px;

    ol {
      padding-left: 30px;
    }

    p {
      text-align: center;
      padding-bottom: 10px;
    }

    a {
      text-decoration: underline;
    }

    li {
      list-style-type: decimal;
      display: list-item;
    }

    ul {
      padding-left: 30px;

      li {
        list-style-type: circle;
      }
    }
  }

  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0008 = () => (
  <Wrapper>
    <ColumnH1 label="多様な働き方、変形労働時間制とは？採用するメリットとは" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2022.04.15</p>
    <section>
      <img src="/images/column/details/c0008.jpg" alt="キンクラコラム" />
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        働き方改革により時間、場所、契約形態の多様化が促進され、一部のエッセンシャルワーカーを除き、「顔が見えない」中での労務管理も一般的になりつつあります。
        <br />
        <br />
        今回は通常の時間管理がそぐわない業種に対して採用されている
        <span>変形労働時間制</span>について解説してまいります。
      </p>
      <div className="toc">
        <p>目次</p>
        <ol>
          <li>
            <a href="#toc1">変形労働時間制とは</a>
          </li>
          <li>
            <a href="#toc2">変形労働時間制の種類</a>
            <ul>
              <li>
                <a href="#toc2-1">(1) 1か月単位の変形労働時間制</a>
              </li>
              <li>
                <a href="#toc2-2">(2) フレックスタイム制</a>
              </li>
              <li>
                <a href="#toc2-3">(3) 1年単位の変形労働時間制</a>
              </li>
              <li>
                <a href="#toc2-4">(4) 1週間単位の変形労働時間制</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#toc2">変形労働時間制を採用した場合としない場合の相違点</a>
          </li>
          <li>
            <a href="#toc4">変形労働時間制の不適切な運用とは</a>
          </li>
          <li>
            <a href="#toc5">変形労働時間制と割増賃金の関係</a>
          </li>
          <li>
            <a href="#toc6">まとめ</a>
          </li>
        </ol>
      </div>
      <div id="toc1">
        <h1>変形労働時間制とは</h1>
      </div>
      <p>
        月末や特定の週に業務の繁忙を迎える企業の場合、労働者の生活設計を損なわない限りにおいて、労働時間の弾力的な運用を行うことを法的に可能とする制度です。根拠規定は労働法の基本である労働基準法に設けられており、例として医療業界などでは一般的に採用されています。
        <br />
        <br />
        採用するメリットとしては我が国の労働時間は労働基準法32条により1日8時間、1週間で40時間までとされています。それらの時間を超えて働かせる場合は
        <span>
          就業規則に根拠規定を設け、36協定を締結し、所轄労働基準監督署へ届け出
        </span>
        なければ違法となります。当然、「残業時間」には通常の賃金ではなく、「割増賃金」として支払うことが義務付けられ、労働者の数が増えれば増えるほど経営上も無視することができません。
        <br />
        しかし、変形労働時間制を採用することにより割増賃金の負担が緩和され、労働者としても効率的な労務の提供が可能（結果的に労働時間が短くなることもある）となります。
      </p>
      <div id="toc2">
        <h1>変形労働時間制の種類</h1>
      </div>
      <p>
        変形労働時間制は、下記の通り大きく分けて4種類あります。
        <br />
        (1)1か月単位の変形労働時間制
        <br />
        (2)フレックスタイム制
        <br />
        (3)1年単位の変形労働時間制
        <br />
        (4)1週間単位の変形労働時間制
        <br />
        <br />
        (1)については(4つの中では)労働統計上最も多く採用されています。4つの制度について概要を確認していきましょう。
      </p>
      <div id="toc2-1">
        <h2>(1) 1か月単位の変形労働時間制</h2>
      </div>
      <p>
        1か月以内の一定期間を平均して1週間の労働時間の平均が法定労働時間を超えなければ、
        <span>特定の週や日に法定労働時間を超えて労働</span>
        させることが可能となります。
        <br />
        <br />
        採用するための手続きは就業規則または労使協定の締結（届出必要）となります。
      </p>
      <h3>スタンダードなパターン</h3>
      <img
        className="img-graph"
        src="/images/column/details/c0008_g01.jpg"
        alt="キンクラコラム"
      />
      <p>
        ※10時間+8時間+8時間+8時間+6時間=40時間
        <br />
        ※月、金ともに予め特定しておくこと
      </p>
      <div id="toc2-2">
        <h2>(2) フレックスタイム制</h2>
      </div>
      <p>
        一定期間の総労働時間を定めておき、始業および就業の時刻を労働者が選択して働くことが可能となる制度です。
        <br />
        尚、2019年4月1日より一定期間（正確には清算期間、以下清算期間）の上限が
        <span>1か月から3か月に延長</span>
        され、より柔軟な制度設計が可能となりました。
        <br />
        <br />
        採用するための手続きは就業規則に根拠規定を設け、労使協定の締結が必要となります。そして、清算期間が1か月以内の場合は労使協定の届出は不要ですが、1か月超の場合は所轄労働基準監督署へ（労使協定の）届出が必要です。
      </p>
      <h3>スタンダードなパターン（法改正対応・3か月）</h3>
      <img
        className="img-graph"
        src="/images/column/details/c0008_g02.jpg"
        alt="キンクラコラム"
      />
      <p>
        ※1改正前は1か月で精算することからこの部分は割増賃金が必要であった
        <br />
        ※2所定労働時間働いていないため、改正前は欠勤扱い
        <br />
        ※精算期間を3か月とすることで※1の割増賃金の支払いが不要
        <br />
        ※6月の労働分は8月に労働しなくても欠勤扱いとならない
      </p>
      <div id="toc2-3">
        <h2>(3) 1年単位の変形労働時間制</h2>
      </div>
      <p>
        <span>
          1か月を超えて1年以内の一定期間を平均し1週間の平均が40時間を超えていなければ特定の週や日に法定労働時間を超えて働かせることが可能
        </span>
        となります。
        <br />
        <br />
        採用するための手続きは就業規則に根拠規定を設け、労使協定の締結が必要であり、労使協定の届出も必要です。
      </p>
      <h3>スタンダードなパターン</h3>
      <img
        className="img-graph"
        src="/images/column/details/c0008_g03.jpg"
        alt="キンクラコラム"
      />
      <p>
        ※連続労働日数の限度は原則として最長6日まで
        <br />
        ※特定期間を設定すると1週間に1日の休日も可能（最長12日出勤可能）
        <br />
        ※特定期間の設定は労使協定が必要だが大部分を特定期間と定める協定は認められない
      </p>
      <div id="toc2-4">
        <h2>(4) 1週間単位の変形労働時間制</h2>
      </div>
      <p>
        <span>採用できる業種に制限</span>
        があり、常時使用する労働者数が30人未満の小売業、旅館、料理店、飲食店が対象となります。変形労働の内容は
        <span>1週間の労働時間を40時間以内</span>
        と定めれば1日10時間まで労働させることができます。
        <br />
        <br />
        採用するための手続きは就業規則に根拠規定を設け、労使協定の締結が必要であり、労使協定の届出も必要です。
      </p>
      <h3>スタンダードなパターン</h3>
      <img
        className="img-graph"
        src="/images/column/details/c0008_g04.jpg"
        alt="キンクラコラム"
      />
      <p>
        ※採用できる業種に限りがあり、小売業、旅館、料理店および飲食店に限る
        <br />
        ※常時使用する労働者数が30人未満であること
      </p>
      <Introduction002 />
      <div id="toc3">
        <h1>変形労働時間制を採用した場合としない場合の相違点</h1>
      </div>
      <p>
        通常の時間管理を採用する場合、労働者が1日8時間、1週間で40時間を超えて労働した際、割増賃金の支払いを免れることはできません。
        <br />
        労働とは言えず、指揮命令下になく単なる滞留時間であれば残業代の支払いは必要ありませんが、そのような時間のみと言うケースは稀でしょう。
        <br />
        <br />
        そこで、制度として変形労働時間制を採用し、
        <span>業務の繁忙度に合わせて労働時間を弾力的に変更</span>
        （例えば月末と月初が繁忙となる企業で1か月単位の変形労働時間制採用する場合、月末と月初の労働時間を延ばし、月の半ばの労働時間を減らす）することで、
        <span>割増賃金の支払い回避</span>できます。
      </p>
      <div id="toc4">
        <h1>変形労働時間制の不適切な運用とは</h1>
      </div>
      <p>
        変形労働時間制を採用することにより割増賃金の支払いを回避できる点はメリットです。
        <br />
        しかし、変形労働時間制を採用したとしても深夜割増と休日割増を回避することはできません。
        <br />
        よって、繁忙であったとしても深夜帯（22時から翌朝5時）に及ぶ労働の提供を受容すると深夜割増分を付加した賃金の支払いが義務となり、法定休日の定めがある場合（例えば日曜日が法定休日と定めている場合は日曜日）にその日に働かせると休日割増分を付加した賃金の支払いが義務となります。
        <br />
        変形労働時間を採用すると例外なく割増賃金が免除されるわけではありません。
      </p>
      <div id="toc5">
        <h1>変形労働時間制と割増賃金の関係</h1>
      </div>
      <p>
        変形労働時間制と割増賃金の関係について、例えば1か月変形労働時間制であれば1か月以内の一定期間を平均して1週間の労働時間の平均が法定労働時間を超えていないかを確認し、
        <span>割増賃金の支払い義務の有無を確認</span>します。
        <br />
        <br />
        尚、割増賃金が必要か否かについては、1日について、1週間について、法定労働時間について、変形期間全体を超えていないかを確認する必要があります。
        <br />
        <br />
        <span>「1日について」</span>
        とは8時間を超える時間を定めた日はその時間を超えて労働した時間であり、それ以外の日は8時間を超えて労働した時間ということです。
        <br />
        <br />
        <span>「1週間について」</span>
        とは1週間の法定労働時間を超える定めをした週はその時間を超えて労働した時間であり、それ以外の週は1週間の法定労働時間を超えて労働した時間（1日単位で時間外労働と換算した時間を除く）
        <br />
        <br />
        <span>「変形期間全体」</span>
        とは31日の日は177.1時間と定められており、当該時間を超えていないか否かを確認する必要があります。
      </p>
      <div className="link-div">
        参考（3頁）
        <p className="link-p">
          <Link
            className="p-list-link"
            to="https://www.mhlw.go.jp/new-info/kobetu/roudou/gyousei/dl/140811-2.pdf"
            target="_blank"
            rel="noreferrer"
          >
            厚生労働省
          </Link>
        </p>
      </div>
      <p>
        フレックスタイム制では
        <span>清算期間（3か月以内）内での時間管理が重要</span>
        となり、特に清算期間が1か月を超える場合は法定労働時間の総枠を超えず、かつ、清算期間開始日以後1か月ごとに区分した期間ごとに平均して1週間あたりの労働時間が50時間を超えないことが要件となります。
        <br />
        <br />
        1年単位の変形労働時間制については1か月単位より長いスパンでの弾力的な時間管理が可能であることから1日の労働時間の限度が10時間、1週間の労働時間の限度が52時間と定められている点は抜け落ちがちな部分です。
        <br />
        <br />
        1週間単位の変形労働時間制はそもそも採用できる業種に制限がある点が極めて重要です。
      </p>
      <div id="toc6">
        <h1 className="read">まとめ</h1>
      </div>
      <p>
        変形労働時間制は正しく運用することで経営的にも合理的な選択肢となりますが、誤って運用すると労働者にとって不利な労働条件となり、是正勧告の対象となります。導入または導入後に運用していく中で疑義が生じた場合は
        <span>行政機関や専門家に助言を求める</span>ことが重要です。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0008
