import { graphql } from 'gatsby'
import React from 'react'
import ColumnBackAndForth from '../../../components/atoms/columnBackAndForth'
import SEO from '../../../components/atoms/seo'
import { ITopicPath } from '../../../components/atoms/topicPath'
import ColumnAuthor001 from '../../../components/molecules/columnAuthor001'
import ColumnDetailInner0008 from '../../../components/molecules/columnDetailInner0008'
import UsefulFunctionLayout023 from '../../../components/molecules/usefulFunctionLayout023'
import ColumnDetailsBody from '../../../components/organisms/columnDetailsBody'
import ColumnLayout from '../../../components/organisms/columnLayout'
import useRouteParam from '../../../hooks/useRouteParam'

/**
 * コラム記事詳細画面
 * @constructor
 */
const C0008 = () => {
  const paths: ITopicPath[] = [
    { title: 'キンクラコラムTOP', link: useRouteParam('/column') },
    { title: 'コラム記事一覧', link: useRouteParam('/column/list') },
    {
      title: '多様な働き方、変形労働時間制とは？採用するメリットとは',
    },
  ]

  return (
    <ColumnLayout paths={paths}>
      <SEO
        title="多様な働き方、変形労働時間制を採用するメリットや注意点は？"
        description="変形労働時間制は労働時間を週単位・月単位・年単位で調整できる働き方です。繁忙期や閑散期に合わせて労働時間を調整でき、正しく運用すれば経営にもメリットがあります。"
        ogUrl="https://kintaicloud.jp/column/details/C0008/"
        ogType="article"
        ogTitle="多様な働き方、変形労働時間制を採用するメリットや注意点は？"
        ogDescription="変形労働時間制は労働時間を週単位・月単位・年単位で調整できる働き方です。繁忙期や閑散期に合わせて労働時間を調整でき、正しく運用すれば経営にもメリットがあります。"
        ogSiteName="キンクラ - 客先常駐特化型のクラウド勤怠管理システム"
        ogImage="https://www.kintaicloud.jp/images/column/details/c0008.jpg"
      />
      <ColumnDetailsBody>
        {/** 記事本体 */}
        <ColumnDetailInner0008 />
        {/** オーサ情報 */}
        <ColumnAuthor001 />
        {/** お役立ち機能 */}
        <UsefulFunctionLayout023 />
        {/** 前後記事への遷移 */}
        <ColumnBackAndForth />
      </ColumnDetailsBody>
    </ColumnLayout>
  )
}

export default C0008

export const pageQuery = graphql`
  query C0008 {
    site {
      siteMetadata {
        title
      }
    }
  }
`
